import { ResponseErrorCode } from '@corify/helpers/api/error-code-map';
import { downloadFileFromResponse, isAttachmentResponse, isJsonResponse } from '@corify/helpers/api/helper';

import { StatusError } from '../error-boundary/status-error';

export type SwrApiError<Body = any> = Error & {
  status?: number;
  errorCode?: ResponseErrorCode;
  body: Body;
  requestUrl?: string;
};

export const fetcher = async <ErrorBody = string | object | undefined>(
  input: RequestInfo | URL,
  init?: RequestInit
) => {
  return fetch(input, init).then(async response => {
    const data = isAttachmentResponse(response)
      ? downloadFileFromResponse(response)
      : isJsonResponse(response)
        ? await response.json()
        : await response.text();

    if (!response.ok) {
      if (response.status === 401) {
        throw new StatusError('SESSION_EXPIRED');
      }

      const error = new Error(data?.message || response.statusText) as SwrApiError<ErrorBody>;

      error.status = response.status;
      error.errorCode = data.errorCode;
      error.body = data;
      error.requestUrl = typeof input !== 'string' ? ('url' in input ? input.url : input.toString()) : input;

      throw error;
    }

    return data;
  });
};
