import { useAuth0 } from '@auth0/auth0-react';
import { addUrlParameters } from '@corify/helpers/url';
import { useTranslation } from 'react-i18next';

import { useErrorBoundary } from '../error-boundary/use-error-boundary';
import { errorToast } from '../toast/toast';
import { fetcher } from './fetcher';

type SwrApiExtraArg = RequestInit & { query?: Record<string, string>; payload?: object };
type FetcherPayload = { url: string; payload: unknown };

export const useSwrApiFetcher = () => {
  const errorHandler = useErrorBoundary();
  const { getAccessTokenSilently } = useAuth0();
  const {
    i18n: { language },
  } = useTranslation();

  const swrApiFetcher =
    <Data, ExtraArg = SwrApiExtraArg>(
      method: 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'GET' = 'POST',
      defaultErrorMessage?: string,
      errorBoundary?: boolean
    ) =>
    async (key: string | FetcherPayload, options?: { arg: ExtraArg }): Promise<Data> => {
      const { query, body, payload, ...rest } = {
        body: null,
        query: undefined,
        headers: {},
        payload: typeof key === 'string' ? undefined : key?.payload,
        ...options?.arg,
      };

      const token = await getAccessTokenSilently();

      const typedBody = body as string | FormData | null;
      //  TO NOTE: for form data, the content type is set automatically
      const isFormData = typedBody instanceof FormData;
      const defaultContentTypeHeader = { 'Content-Type': 'application/json' };

      const headers: HeadersInit = {
        ...(!isFormData && defaultContentTypeHeader),
        ...(rest.headers ?? {}),
        Authorization: `Bearer ${token}`,
        ['Accept-Language']: language === 'en' ? 'en-GB' : 'de-DE',
      };

      return await fetcher(addUrlParameters(typeof key === 'string' ? key : key.url, query ?? {}), {
        method,
        ...rest,
        body: payload ? JSON.stringify(payload) : body,
        headers,
      }).catch(error => {
        if (errorBoundary) {
          errorHandler(error as Error);
        }

        if (defaultErrorMessage) {
          errorToast(defaultErrorMessage);
        }
        throw error;
      });
    };

  return swrApiFetcher;
};
