import Icon404 from '@corify/assets/icons/404.svg?react';
import Icon500 from '@corify/assets/icons/500.svg?react';
import Icon503 from '@corify/assets/icons/503.svg?react';
import Icon504 from '@corify/assets/icons/504.svg?react';
import Account from '@corify/assets/icons/account.svg?react';
import Add from '@corify/assets/icons/add.svg?react';
import AddPlus from '@corify/assets/icons/addplus.svg?react';
import AddPlusWhite from '@corify/assets/icons/addplus_white.svg?react';
import Alert from '@corify/assets/icons/alert.svg?react';
import ArrowDown from '@corify/assets/icons/arrow/down.svg?react';
import ArrowLeft from '@corify/assets/icons/arrow/left.svg?react';
import ArrowRight from '@corify/assets/icons/arrow/right.svg?react';
import ArrowUp from '@corify/assets/icons/arrow/up.svg?react';
import Ascending from '@corify/assets/icons/ascending.svg?react';
import AvatarBroker from '@corify/assets/icons/avatar-broker.svg?react';
import AvatarCustomer from '@corify/assets/icons/avatar-customer.svg?react';
import AvatarInsurer from '@corify/assets/icons/avatar-insurer.svg?react';
import Back from '@corify/assets/icons/back.svg?react';
import BackCurrentColor from '@corify/assets/icons/back_current_color.svg?react';
import Bell from '@corify/assets/icons/bell.svg?react';
import Calender from '@corify/assets/icons/calender.svg?react';
import Check from '@corify/assets/icons/check.svg?react';
import CheckCircle from '@corify/assets/icons/check_circle.svg?react';
import CheckOutline from '@corify/assets/icons/check_outline.svg?react';
import CheckboxChecked from '@corify/assets/icons/checkbox_checked.svg?react';
import CheckboxCheckedFilled from '@corify/assets/icons/checkbox_checked_filled.svg?react';
import CheckboxDisabled from '@corify/assets/icons/checkbox_disabled.svg?react';
import CheckboxUnchecked from '@corify/assets/icons/checkbox_unchecked.svg?react';
import CircleArrow from '@corify/assets/icons/circle_arrow.svg?react';
import Clear from '@corify/assets/icons/clear.svg?react';
import ClearGrey from '@corify/assets/icons/clear_grey.svg?react';
import Close from '@corify/assets/icons/close.svg?react';
import Cog from '@corify/assets/icons/cog.svg?react';
import CorifyLogoWhite from '@corify/assets/icons/corify_logo_white.svg?react';
import Delete from '@corify/assets/icons/delete.svg?react';
import DeleteRed from '@corify/assets/icons/delete_red.svg?react';
import Descending from '@corify/assets/icons/descending.svg?react';
import Download from '@corify/assets/icons/download.svg?react';
import Duplicate from '@corify/assets/icons/duplicate.svg?react';
import Edit from '@corify/assets/icons/edit.svg?react';
import Enterprise from '@corify/assets/icons/enterprise.svg?react';
import ErrorOutline from '@corify/assets/icons/error_outline.svg?react';
import ExpandLess from '@corify/assets/icons/expand_less.svg?react';
import ExpandMore from '@corify/assets/icons/expand_more.svg?react';
import EyeHidden from '@corify/assets/icons/eye_hidden.svg?react';
import EyeVisible from '@corify/assets/icons/eye_visible.svg?react';
import File from '@corify/assets/icons/file.svg?react';
import Help from '@corify/assets/icons/help.svg?react';
import Inactive from '@corify/assets/icons/inactive.svg?react';
import Info from '@corify/assets/icons/info.svg?react';
import InfoActive from '@corify/assets/icons/info_active.svg?react';
import InfoStandard from '@corify/assets/icons/info_standard.svg?react';
import InviteUser from '@corify/assets/icons/invite_user.svg?react';
import Language from '@corify/assets/icons/language.svg?react';
import Link from '@corify/assets/icons/link.svg?react';
import Lock from '@corify/assets/icons/lock.svg?react';
import More from '@corify/assets/icons/more.svg?react';
import NavigationArrow from '@corify/assets/icons/navigation-arrow.svg?react';
import NoData from '@corify/assets/icons/no_data.svg?react';
import OldAccount from '@corify/assets/icons/old-design/account.svg?react';
import OldHelp from '@corify/assets/icons/old-design/help.svg?react';
import Pause from '@corify/assets/icons/pause.svg?react';
import PauseStop from '@corify/assets/icons/pause_stop.svg?react';
import Positive from '@corify/assets/icons/positive.svg?react';
import Progress from '@corify/assets/icons/progress.svg?react';
import ProgressPurple from '@corify/assets/icons/progress_purple.svg?react';
import ProgressWhite from '@corify/assets/icons/progress_white.svg?react';
import RadioButtonChecked from '@corify/assets/icons/radio_button_checked.svg?react';
import RadioButtonUnchecked from '@corify/assets/icons/radio_button_unchecked.svg?react';
import Reload from '@corify/assets/icons/reload.svg?react';
import Remove from '@corify/assets/icons/remove.svg?react';
import Save from '@corify/assets/icons/save.svg?react';
import Search from '@corify/assets/icons/search.svg?react';
import SearchReadOnly from '@corify/assets/icons/search_readonly.svg?react';
import Settings from '@corify/assets/icons/settings.svg?react';
import SomethingWentWrong from '@corify/assets/icons/something_went_wrong.svg?react';
import Star from '@corify/assets/icons/star.svg?react';
import StarActive from '@corify/assets/icons/star_active.svg?react';
import Upload from '@corify/assets/icons/upload.svg?react';
import User from '@corify/assets/icons/user.svg?react';
import UserAdd from '@corify/assets/icons/user_add.svg?react';
import UserRemove from '@corify/assets/icons/user_remove.svg?react';
import Users from '@corify/assets/icons/users.svg?react';
import UsersWhite from '@corify/assets/icons/users-white.svg?react';
import Versions from '@corify/assets/icons/versions.svg?react';
import { cn } from '@corify/helpers/cn';
import { kebabCase } from 'lodash-es';
import { ElementType, SVGAttributes } from 'react';

export const mappedIcons = {
  '404': Icon404,
  '500': Icon500,
  '503': Icon503,
  '504': Icon504,
  account: Account,
  old_account: OldAccount,
  add: Add,
  addplus: AddPlus,
  alert: Alert,
  ascending: Ascending,
  back: Back,
  bell: Bell,
  calender: Calender,
  check: Check,
  check_outline: CheckOutline,
  check_circle: CheckCircle,
  clear: Clear,
  clear_grey: ClearGrey,
  close: Close,
  cog: Cog,
  delete: Delete,
  delete_red: DeleteRed,
  descending: Descending,
  download: Download,
  duplicate: Duplicate,
  edit: Edit,
  enterprise: Enterprise,
  help: Help,
  old_help: OldHelp,
  info: Info,
  invite_user: InviteUser,
  inactive: Inactive,
  language: Language,
  link: Link,
  navigation_arrow: NavigationArrow,
  lock: Lock,
  more: More,
  no_data: NoData,
  pause: Pause,
  pauseStop: PauseStop,
  positive: Positive,
  progress: Progress,
  progress_purple: ProgressPurple,
  progress_white: ProgressWhite,
  remove: Remove,
  reload: Reload,
  save: Save,
  search: Search,
  settings: Settings,
  upload: Upload,
  user: User,
  users: Users,
  users_white: UsersWhite,
  versions: Versions,
  star: Star,
  star_active: StarActive,
  arrow_down: ArrowDown,
  arrow_left: ArrowLeft,
  arrow_right: ArrowRight,
  arrow_up: ArrowUp,
  back_current_color: BackCurrentColor,
  addplus_white: AddPlusWhite,
  checkbox_checked: CheckboxChecked,
  checkbox_checked_filled: CheckboxCheckedFilled,
  checkbox_disabled: CheckboxDisabled,
  checkbox_unchecked: CheckboxUnchecked,
  circle_arrow: CircleArrow,
  corify_logo_white: CorifyLogoWhite,
  error_outline: ErrorOutline,
  expand_less: ExpandLess,
  expand_more: ExpandMore,
  eye_hidden: EyeHidden,
  eye_visible: EyeVisible,
  file: File,
  info_active: InfoActive,
  info_standard: InfoStandard,
  radio_button_checked: RadioButtonChecked,
  radio_button_unchecked: RadioButtonUnchecked,
  search_readonly: SearchReadOnly,
  something_went_wrong: SomethingWentWrong,
  user_add: UserAdd,
  user_remove: UserRemove,
  avatar_customer: AvatarCustomer,
  avatar_broker: AvatarBroker,
  avatar_insurer: AvatarInsurer,
} as const;

export type IconName = keyof typeof mappedIcons;

interface Props extends SVGAttributes<HTMLOrSVGElement> {
  name: IconName;
  className?: string;
  testId?: string;
}

export const Icon = ({ name, onClick, className, testId, ...props }: Props) => {
  if (!mappedIcons[name]) {
    throw new Error(
      `Icon does not exist, you provided icon name ${name} allowed names are ${Object.keys(mappedIcons).join(', ')}`
    );
  }

  const ReactComponent = mappedIcons[name] as ElementType;

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <ReactComponent
      onClick={onClick}
      className={cn('inline-block', className, {
        'cursor-pointer': typeof onClick === 'function',
      })}
      role="img"
      data-testid={kebabCase(testId || `icon-${name}`)}
      {...props}
    />
  );
};
