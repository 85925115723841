import ModelManagerHsnTsn from '@corify/pages/model-manager/model-manager-hsn-tsn';
import { lazy } from 'react';
import { Route } from 'react-router-dom';

import { MODELER_ROUTES } from './routes';

const ModelManager = lazy(() => import('@corify/pages/model-manager/model-manager'));
const ModelManagerReleases = lazy(() => import('@corify/pages/model-manager/model-manager-releases'));
const ScromAttributes = lazy(() => import('@corify/pages/model-manager/scrom-attributes'));
const ModelManagerLayout = lazy(() => import('@corify/layout/model-manager-layout'));
const LobConfiguration = lazy(() => import('@corify/pages/model-manager/lob-configuration'));

export const getModelMangerRoutes = (cromRelease: boolean, lobConfiguration: boolean) => [
  <Route key="modeler" element={<ModelManagerLayout cromRelease={cromRelease} lobConfiguration={lobConfiguration} />}>
    <Route path={MODELER_ROUTES.MODELER_ROUTE_CROM} index element={<ModelManager cromRelease={cromRelease} />} />
    {cromRelease && <Route path={MODELER_ROUTES.MODELER_ROUTE_CROM_RELEASES} element={<ModelManagerReleases />} />}
    <Route path={MODELER_ROUTES.MODELER_ROUTE_SCROM_ATTRIBUTES} element={<ScromAttributes />} />
    <Route path={MODELER_ROUTES.MODELER_ROUTE_HSN_TSN} element={<ModelManagerHsnTsn />} />
    {lobConfiguration && <Route path={MODELER_ROUTES.MODELER_ROUTE_LOB_CONFIGURATION} element={<LobConfiguration />} />}
  </Route>,
];
